import React, { useEffect, useState } from "react";
import { currentUrl } from "../../App";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { fetchBeds, fetchPossibleBedOccupants } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import BedAssignmentCard from "./BedAssignmentCard";

interface BedAssignmentState {
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  getData: boolean;
}

const BedAssignment = ({ setOrgToSave, setShowToast, setToastText, setToastBody, getData }: BedAssignmentState) => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [beds, setBeds] = useState<any[]>([]);
  const [vacantBeds, setVacantBeds] = useState<any[]>([]);
  const [possibleOccupants, setPossibleOccupants] = useState<any[]>([]);
  const [showVacant, setShowVacant] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getBeds();
    }
  }, [getData]);

  const getBeds = () => {
    fetchBeds()
      .then((res: any) => {
        const bedList = res[0].data;
        setBeds(bedList);
        const vacantBedList = bedList.filter((bed: any) => bed.occupant_id === null);
        setVacantBeds(vacantBedList);
        getPossibleOccupants();
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPossibleOccupants = () => {
    fetchPossibleBedOccupants().then((res: any) => {
      const initialPossible = res[0].data;
      setPossibleOccupants(initialPossible);
    });
  };

  const onClickVacant = () => {
    setShowVacant(!showVacant);
    setErrMsg("");
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <>
            <PageTitle
              title="Bed Assignment"
              type="section"
              buttonTitle={showVacant ? "View All" : "View Vacant"}
              onButtonClick={() => onClickVacant()}
            />
            {showVacant ? (
              <Row className="pb-5 mt-2">
                <Col xs={12} lg={10} xl={8}>
                  {vacantBeds.length === 0 ? (
                    <small>No Vacant Beds</small>
                  ) : (
                    vacantBeds?.map((item: any) => (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Row className="mb-1"></Row>
                            <BedAssignmentCard
                              item={item}
                              getItems={getBeds}
                              beds={vacantBeds}
                              possibleOccupants={possibleOccupants}
                              setErrMessage={setErrMsg}
                              setShowToast={setShowToast}
                              setToastText={setToastText}
                              setToastBody={setToastBody}
                            />
                          </Col>
                        </Row>
                      </>
                    ))
                  )}
                </Col>
              </Row>
            ) : (
              <Row className="pb-5 mt-2">
                <Col xs={12} lg={10} xl={8}>
                  {beds.length === 0 ? (
                    <small>No Beds Created</small>
                  ) : (
                    beds?.map((item: any) => (
                      <>
                        <Row>
                          <Col xs={12}>
                            <Row className="mb-1"></Row>
                            <BedAssignmentCard
                              item={item}
                              getItems={getBeds}
                              beds={beds}
                              possibleOccupants={possibleOccupants}
                              setErrMessage={setErrMsg}
                              setShowToast={setShowToast}
                              setToastText={setToastText}
                              setToastBody={setToastBody}
                            />
                          </Col>
                        </Row>
                      </>
                    ))
                  )}
                </Col>
              </Row>
            )}
            <Row className="mb-5"></Row>
          </>
        </>
      )}
    </>
  );
};

export default BedAssignment;
