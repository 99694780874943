import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { patchNote, postSummary } from "../../../../services/httpClient";
import Contact from "./Contact";
import Intervention from "./Intervention";
import Referral from "./Referral";
import Standard from "./Standard";
import StudentConnections from "./StudentConnections";
import useApp from "../../../../utils/useApp";
import { User } from "../../../../types/user";
import Infraction from "./Infraction";

interface EditNoteState {
  fetchNotes: any;
  note: any;
  family: User[];
  setViewNotes: any;
  setEditNote: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  saveIsEnabled: boolean;
  setSaveIsEnabled: any;
  setUserToSave: any;
  setSummary: any;
  myCase: boolean;
}

const EditNote = ({
  fetchNotes,
  note,
  family,
  setViewNotes,
  setEditNote,
  setShowToast,
  setToastText,
  setToastBody,
  saveIsEnabled,
  setSaveIsEnabled,
  setUserToSave,
  setSummary,
  myCase,
}: EditNoteState) => {
  const { id } = useParams();
  const { showMenu }: any = useApp();
  const [updatedNote, setUpdatedNote] = useState<any>({});
  const [docs, setDocs] = useState<any[]>([]);

  const title = () => {
    if (note.notetype === "student") {
      return "Student Connections";
    } else if (note.notetype === "intervention") {
      return "Intervention Follow-Up";
    } else {
      return note.notetype.charAt(0).toUpperCase() + note.notetype.slice(1);
    }
  };

  const onClickSave = () => {
    patchNote(updatedNote, id, note?.id).then((res: any) => {
      fetchNotes();

      postSummary(id)
        .then((res: any) => {
          var magic = res[0].data.summary.postResponse;

          setSummary(magic);
        })
        .catch((err: any) => {
          console.log(err);
        });

      setSaveIsEnabled(false);
      setUserToSave(false);
      setViewNotes(true);
      setEditNote(false);
      setShowToast(true);
      setToastText("Note Updated");
      setToastBody(title());
    });
  };

  return (
    <>
      <Card
        className={showMenu ? "bg-light sticky-top card-note-menu-open-sticky" : "bg-light sticky-top card-note-sticky"}
      >
        <Row className="pe-0 mb-3 mt-3">
          <Col className="col-6 d-flex align-items-center">
            <h5 className="app-orange-text mb-0">{title()}</h5>
          </Col>
          <Col className="col-4"></Col>
          <Col className="col-2 d-flex justify-content-end align-items-center">
            {myCase && (
              <Button className="app-secondary-color button" onClick={() => onClickSave()} disabled={!saveIsEnabled}>
                Save
              </Button>
            )}
          </Col>
        </Row>
      </Card>

      {note?.notetype === "standard" && (
        <Standard
          note={note}
          family={family}
          documents={docs}
          setDocuments={setDocs}
          setUpdatedNote={setUpdatedNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={myCase}
          editNote={true}
        />
      )}
      {note?.notetype === "contact" && (
        <Contact
          note={note}
          family={family}
          setUpdatedNote={setUpdatedNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={myCase}
          editNote={true}
        />
      )}
      {note?.notetype === "intervention" && (
        <Intervention
          note={note}
          family={family}
          setUpdatedNote={setUpdatedNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={myCase}
          editNote={true}
        />
      )}
      {note?.notetype === "referral" && (
        <Referral
          note={note}
          family={family}
          setUpdatedNote={setUpdatedNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={myCase}
          editNote={true}
        />
      )}
      {note?.notetype === "student" && (
        <StudentConnections
          note={note}
          family={family}
          setUpdatedNote={setUpdatedNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={myCase}
          editNote={true}
        />
      )}
      {note?.notetype === "infraction" && (
        <Infraction
          note={note}
          family={family}
          setUpdatedNote={setUpdatedNote}
          setSaveIsEnabled={setSaveIsEnabled}
          setUserToSave={setUserToSave}
          myCase={myCase}
          editNote={true}
        />
      )}
    </>
  );
};

export default EditNote;
