import React, { useEffect, useState } from "react";
import { currentUrl, unsavedChanges } from "../../App";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Spinner, Stack, Table } from "react-bootstrap";
import { fetchBedChecks, fetchBeds, postBedCheck, postReportLog } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import BedOccupancyCard from "./BedOccupancyCard";

interface BedOccupancyState {
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  getData: boolean;
}

const BedOccupancy = ({ setOrgToSave, setShowToast, setToastText, setToastBody, getData }: BedOccupancyState) => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [beds, setBeds] = useState<any[]>([]);
  const [missingBeds, setMissingBeds] = useState<any[]>([]);
  const [resetCards, setResetCards] = useState<boolean>(false);
  const [showMissingReport, setShowMissingReport] = useState<boolean>(false);
  const [bedChecks, setBedChecks] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getBeds();
    }
  }, [getData]);

  const getBeds = () => {
    fetchBeds()
      .then((res: any) => {
        const bedList = res[0].data;
        setBeds(bedList);
        setResetCards(false);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickSubmit = () => {
    const report = {
      name: "Bed Occupancy Check",
    };

    postReportLog(report).then((res: any) => {});

    missingBeds.map((m: any, index: number) => {
      const bedObject = {
        bed_id: m?.id,
        occupant_id: m?.occupant_id,
        occupant_type: m?.occupant_type,
      };

      postBedCheck(bedObject).then((res: any) => {
        if (missingBeds.length === index + 1) {
          setShowToast(true);
          setToastText("Bed Occupancy Submitted");
          setToastBody("");
          unsavedChanges.value = false;
          setOrgToSave(false);
          setMissingBeds([]);
          setResetCards(true);
          getBeds();
        }
      });
    });
  };

  const onClickCancel = () => {
    unsavedChanges.value = false;
    setOrgToSave(false);
    setMissingBeds([]);
    setResetCards(true);
    getBeds();
  };

  const onClickViewReport = () => {
    fetchBedChecks()
      .then((res: any) => {
        const bedList = res[0].data;
        setBedChecks(bedList);
        setShowMissingReport(true);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const onClickViewCheck = () => {
    setShowMissingReport(false);
  };

  return showMissingReport === true ? (
    <>
      <PageTitle
        title="Bed Occupancy Report"
        type="section"
        buttonColor="primary"
        buttonTitle="Done"
        onButtonClick={() => onClickViewCheck()}
      />
      <Row className="pb-5 mt-2">
        <Col xs={12} lg={8}>
          <Row>
            <h5 className="app-header-text">Missing Report Last 7 Days</h5>
          </Row>
          {bedChecks.length > 0 ? (
            <>
              <Table striped>
                <thead>
                  <tr className="text-muted">
                    <th>Date</th>
                    <th>Bed Name</th>
                    <th>Occupant Name</th>
                    <th>Submitted By</th>
                  </tr>
                </thead>
                <tbody>
                  {bedChecks?.map((m: any) => (
                    <tr key={m.id}>
                      <td>{m.created_at}</td>
                      <td>{m.name}</td>
                      <td>{m.firstname + " " + m.lastname}</td>
                      <td>{m.created_firstname + " " + m.created_lastname}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          ) : (
            <small>No Missing Reported</small>
          )}
        </Col>
      </Row>
    </>
  ) : (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <>
            <PageTitle
              title="Bed Occupancy Check"
              type="section"
              buttonColor="primary"
              buttonTitle="Occupancy Report"
              onButtonClick={() => onClickViewReport()}
            />
            <Row className="mt-2">
              <Col xs={12} lg={8} xl={6} className="pb-3">
                {beds.length === 0 ? (
                  <small>No Beds Created</small>
                ) : (
                  beds?.map((item: any, index: number) => (
                    <>
                      <Row>
                        <Col xs={12}>
                          <Row className="mb-1"></Row>
                          <BedOccupancyCard
                            item={item}
                            setMissingBeds={setMissingBeds}
                            resetCards={resetCards}
                            setOrgToSave={setOrgToSave}
                          />
                        </Col>
                      </Row>
                    </>
                  ))
                )}
              </Col>
              {/* {beds.length > 0 && (
                <Col xs={12} lg={8} xl={6}>
                  <Row>
                    <h5 className="app-header-text">Missing Report</h5>
                  </Row>
                  {missingBeds.length > 0 ? (
                    <>
                      <Table striped>
                        <thead>
                          <tr className="text-muted">
                            <th>Bed Name</th>
                            <th>Occupant Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {missingBeds?.map((m: any) => (
                            <tr key={m.id}>
                              <td>{m.name}</td>
                              <td>{m.firstname + " " + m.lastname}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col className="d-flex align-items-center justify-content-center">
                          <Stack direction="horizontal" gap={3}>
                            <Button className="app-secondary-color button" onClick={() => onClickSubmit()}>
                              Submit
                            </Button>
                            <div className="vr" />
                            <Button className="app-primary-color button" onClick={() => onClickCancel()}>
                              Cancel
                            </Button>
                          </Stack>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <small>No Missing Reported</small>
                  )}
                </Col>
              )} */}
            </Row>
            <Row className="pb-5">
              {beds.length > 0 && (
                <Col xs={12} lg={8} xl={6} className="d-flex align-items-center justify-content-center">
                  <Stack direction="horizontal" gap={3}>
                    <Button className="app-secondary-color button" onClick={() => onClickSubmit()}>
                      Submit
                    </Button>
                    <div className="vr" />
                    <Button className="app-primary-color button" onClick={() => onClickCancel()}>
                      Cancel
                    </Button>
                  </Stack>
                </Col>
              )}
            </Row>
            {/* <Row className="mb-5"></Row> */}
          </>
        </>
      )}
    </>
  );
};

export default BedOccupancy;
