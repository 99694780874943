import React, { useEffect, useState } from "react";
import { Button, Card, Stack } from "react-bootstrap";
import { unsavedChanges } from "../../App";

interface CardState {
  item: any;
  setMissingBeds: any;
  resetCards: boolean;
  setOrgToSave: any;
}

const BedOccupancyCard = ({ item, setMissingBeds, resetCards, setOrgToSave }: CardState) => {
  // const [isOccupied, setIsOccupied] = useState<boolean>(false);
  const [isMissing, setIsMissing] = useState<boolean>(false);

  useEffect(() => {
    setIsMissing(false);
    // setIsOccupied(false);
  }, [resetCards]);

  // const onClickOccupied = () => {
  //   if (!isOccupied) {
  //     setIsOccupied(true);
  //     setIsMissing(false);
  //     unsavedChanges.value = true;
  //     setOrgToSave(true);
  //     setMissingBeds((prev: any) =>
  //       prev.filter((bed: any) => bed.occupant_id !== item.occupant_id && bed.occupant_type !== item.occupant_type)
  //     );
  //   }
  // };

  const onClickMissing = () => {
    if (!isMissing) {
      setIsMissing(true);
      // setIsOccupied(false);
      unsavedChanges.value = true;
      setOrgToSave(true);
      setMissingBeds((prev: any) => [...prev, item]);
    }
  };

  return (
    <Card key={item.name} className="bg-light mb-0">
      <Card.Body className="p-2">
        <Stack direction="horizontal" gap={3}>
          <Stack gap={2} className="align-self-center">
            <Card.Subtitle className="text-muted">
              <small>{item.name}</small>
            </Card.Subtitle>
          </Stack>
          {!item.occupant_id && <small className="fw-light fst-italic p-0 m-0">{"Vacant"}</small>}
          {item.occupant_id && (
            <>
              {/* <Button
                className={isOccupied ? "app-secondary-color button" : "app-secondary-color-inverse button"}
                onClick={() => onClickOccupied()}
              >
                Occupied
              </Button>
              <div className="vr" /> */}
              <Button
                className={isMissing ? "app-tertiary-color button" : "app-tertiary-color-inverse button"}
                onClick={() => onClickMissing()}
              >
                Missing
              </Button>
            </>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
};

export default BedOccupancyCard;
