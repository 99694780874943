import React from "react";
import { Badge, Card, Col, Form, Row } from "react-bootstrap";
import { Note } from "../../../../types/note";

interface BanViewState {
  n: Note;
}

const BanView = ({ n }: BanViewState) => {
  return (
    <Card className="bg-light mb-3">
      <Card.Body>
        <>
          <Row>
            <Col>
              <h5 className="app-orange-text">Ban / Unban</h5>
            </Col>
            <Col className="text-end">
              <label className="note-type text-muted me-3">{n.fields.date}</label>
            </Col>
          </Row>
          <Row>
            <Form.Control
              as="textarea"
              type="textarea"
              value={n.fields.reason}
              style={{
                height: "auto",
                pointerEvents: "none",
                backgroundColor: "#e9ecef",
                color: "#6c757d",
              }}
              className=" mb-2"
            />
          </Row>
          <Row className="mt-1">
            <Col className="col-6 text-start">
              <Badge bg="secondary">
                Created By {n.firstname} {n.lastname}
              </Badge>
            </Col>
            <Col className="col-6 text-end">
              <Badge bg="secondary">{n.subject?.name}</Badge>
            </Col>
          </Row>
        </>
      </Card.Body>
    </Card>
  );
};

export default BanView;
