import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, Container, Spinner } from "react-bootstrap";
import { Note } from "../../../../types/note";
import StandardView from "./StandardView";
import CreateNote from "./CreateNote";
import ContactView from "./ContactView";
import InterventionView from "./InterventionView";
import ReferralView from "./ReferralView";
import StudentConnectionsView from "./StudentConnectionsView";
import EditNote from "./EditNote";
import {
  fetchNotes,
  fetchNoteTypes,
  fetchSummary,
  fetchUserCaseGuardians,
  fetchUserCaseMembers,
  postSummary,
} from "../../../../services/httpClient";
import SelectObject from "../../../ui/SelectObject";
import { useParams } from "react-router-dom";
import ToastMsg from "../../../ui/ToastMsg";
import GroupMeetingView from "./GroupMeetingView";
import SaveChangesModal from "../../../ui/SaveChangesModal";
import useApp from "../../../../utils/useApp";
import { User } from "../../../../types/user";
import { trackDataEvent } from "../../../../utils/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import BanView from "./BanView";
import InfractionView from "./InfractionView";

interface NotesState {
  createNote: boolean;
  setCreateNote: any;
  viewNotes: boolean;
  setViewNotes: any;
  editNote: boolean;
  setEditNote: any;
  myCase: boolean;
  setUserToSave: any;
  showModal: boolean;
  setShowModal: any;
  getData: boolean;
}

const Notes = ({
  createNote,
  setCreateNote,
  viewNotes,
  setViewNotes,
  editNote,
  setEditNote,
  myCase,
  setUserToSave,
  showModal,
  setShowModal,
  getData,
}: NotesState) => {
  const { id } = useParams();
  const { showMenu }: any = useApp();
  const [type, setType] = useState<string>("View All");
  const [subject, setSubject] = useState<string>("View All");
  const [currentNote, setCurrentNote] = useState<Note>();
  const [notes, setNotes] = useState<any[]>([]);
  const [filteredNotes, setFilteredNotes] = useState<Note[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [family, setFamily] = useState<User[]>([]);
  const [activeFamily, setActiveFamily] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [summary, setSummary] = useState<string>("");
  const [isGettingSummary, setIsGettingSummary] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsVisible((prev) => !prev);
    }, 1500);

    if (getData) {
      setIsLoading(true);
      getNotes();
      getNotesSummary();
    }

    return () => clearInterval(interval);
  }, [getData]);

  const getNotes = () => {
    fetchNotes(id)
      .then((res: any) => {
        const notes = res[0].data;
        setNotes(notes);
        setFilteredNotes(notes);

        getTypes();
        getFamily();
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getTypes = () => {
    fetchNoteTypes()
      .then((res: any) => {
        setTypes(res[0].data);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getFamily = () => {
    fetchUserCaseGuardians(id, true)
      .then((res: any) => {
        var guards = res[0].data.map((g: any) => {
          const deleted = g.deleted_at ? true : false;

          return {
            id: g.id,
            type: "guardian",
            key: g.firstname + " " + g.lastname,
            name: g.firstname + " " + g.lastname,
            isDeleted: deleted,
          };
        });

        fetchUserCaseMembers(id, true)
          .then((res: any) => {
            var membs = res[0].data.map((m: any) => {
              const deleted = m.deleted_at ? true : false;

              return {
                id: m.id,
                type: "member",
                key: m.firstname + " " + m.lastname,
                name: m.firstname + " " + m.lastname,
                isDeleted: deleted,
              };
            });

            var members = [...guards, ...membs];
            members.sort((a: any, b: any) => a.isDeleted - b.isDeleted);
            setFamily(members);
            setActiveFamily(members.filter((i: any) => i.isDeleted !== true));
          })
          .catch((err: any) => {
            console.log(err);
            setIsLoading(false);
          });
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const doneEditing = () => {
    if (saveIsEnabled) {
      setShowModal(true);
    } else {
      updateViewNotes(true);
    }
  };

  const updateViewNotes = (e: any) => {
    setViewNotes(e);
    setCreateNote(false);
    setEditNote(false);
    setUserToSave(false);
  };

  const updateCreateNotes = (e: any) => {
    setViewNotes(false);
    setCreateNote(e);
    setEditNote(false);
    setShowSummary(false);
    setSaveIsEnabled(false);
  };

  const updateEditNote = (e: any) => {
    setViewNotes(false);
    setCreateNote(false);
    setEditNote(e);
    setShowSummary(false);
  };

  useEffect(() => {
    var tempNotes = notes;

    if (type !== "0" && type !== "View All") {
      tempNotes = tempNotes.filter((i: any) => i.notetype === type);
      trackDataEvent("filter", "note_type");
    }

    if (subject !== "0" && subject !== "View All") {
      tempNotes = tempNotes.filter((i: any) => i.subject?.name === subject);
      trackDataEvent("filter", "note_family_member");
    }

    setFilteredNotes(tempNotes);
  }, [type, subject, notes]);

  const getNotesSummary = async () => {
    fetchSummary(id)
      .then((res: any) => {
        var magic = res[0].data.summary;
        setSummary(magic[0].notes);
      })
      .catch((err: any) => {
        console.log(err);
        setShowSummary(false);
        setIsGettingSummary(false);
      });
  };

  const createNotesSummary = async () => {
    postSummary(id)
      .then((res: any) => {
        var magic = res[0].data.summary.postResponse;
        setSummary(magic);
        setShowSummary(true);
        setIsGettingSummary(false);
      })
      .catch((err: any) => {
        console.log(err);
        setShowSummary(false);
        setIsGettingSummary(false);
      });
  };

  const showNotesSummary = async () => {
    setShowSummary(false);
    setIsGettingSummary(true);
    if (summary?.length === 0 || summary === null) {
      await createNotesSummary();
    } else {
      setTimeout(() => {
        setIsGettingSummary(false);
        setShowSummary(true);
      }, 3000);
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          {viewNotes === true && (
            <>
              <Row>
                <Col>
                  <h4 className="mb-0 app-header-text">Note History</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-tertiary-color button me-3" onClick={() => showNotesSummary()}>
                    <FontAwesomeIcon icon={faWandMagicSparkles} /> Recap
                  </Button>
                  {myCase && (
                    <Button className="app-primary-color button" onClick={() => updateCreateNotes(true)}>
                      Create Note
                    </Button>
                  )}
                </Col>
              </Row>
              {isGettingSummary && (
                <Row className="text-center">
                  <Col>
                    <h6 className={isVisible ? "app-orange-text fade-in-out" : " app-orange-text fade-in-out hidden"}>
                      Generating Recap <FontAwesomeIcon icon={faWandMagicSparkles} />
                    </h6>
                  </Col>
                </Row>
              )}
              {showSummary && (
                <>
                  <h5 className="mb-0 app-header-text">Recap</h5>
                  <Card>
                    <Row className="px-3 py-3">
                      <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word", fontSize: "medium" }}>
                        {summary}
                      </pre>
                    </Row>
                  </Card>
                </>
              )}
              <Card
                className={
                  showMenu ? "bg-light sticky-top card-note-menu-open-sticky" : "bg-light sticky-top card-note-sticky"
                }
              >
                <Row className="pe-0 mb-3 mt-3 ms-1 me-1">
                  <Col className="col-6">
                    <SelectObject
                      initialOption="View All"
                      label="Note Type"
                      value={type}
                      options={types}
                      onUpdate={(e: any) => setType(e.target.value)}
                    />
                  </Col>
                  <Col className="col-6">
                    <SelectObject
                      initialOption="View All"
                      label="Family Member"
                      value={subject}
                      options={family}
                      onUpdate={(e: any) => setSubject(e.target.value)}
                    />
                  </Col>
                </Row>
              </Card>
              <Row className="mx-0">
                {filteredNotes.map(
                  (note: Note) =>
                    (note.notetype === "ban_unban" && <BanView key={note.id} n={note} />) ||
                    (note.notetype === "group" && <GroupMeetingView key={note.id} n={note} />) ||
                    (note.notetype === "standard" && (
                      <StandardView key={note.id} n={note} setEdit={updateEditNote} setCurrent={setCurrentNote} />
                    )) ||
                    (note.notetype === "contact" && (
                      <ContactView key={note.id} n={note} setEdit={updateEditNote} setCurrent={setCurrentNote} />
                    )) ||
                    (note.notetype === "intervention" && (
                      <InterventionView key={note.id} n={note} setEdit={updateEditNote} setCurrent={setCurrentNote} />
                    )) ||
                    (note.notetype === "referral" && (
                      <ReferralView key={note.id} n={note} setEdit={updateEditNote} setCurrent={setCurrentNote} />
                    )) ||
                    (note.notetype === "student" && (
                      <StudentConnectionsView
                        key={note.id}
                        n={note}
                        setEdit={updateEditNote}
                        setCurrent={setCurrentNote}
                      />
                    )) ||
                    (note.notetype === "infraction" && (
                      <InfractionView key={note.id} n={note} setEdit={updateEditNote} setCurrent={setCurrentNote} />
                    ))
                )}
              </Row>
            </>
          )}

          {createNote === true && (
            <>
              <Row>
                <Col>
                  <h4 className="mb-0 app-header-text">Create Note</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => doneEditing()}>
                    Done
                  </Button>
                </Col>
              </Row>
              <Row>
                <CreateNote
                  fetchNotes={getNotes}
                  setViewNotes={setViewNotes}
                  setCreateNote={setCreateNote}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  family={activeFamily}
                  saveIsEnabled={saveIsEnabled}
                  setSaveIsEnabled={setSaveIsEnabled}
                  setUserToSave={setUserToSave}
                  setSummary={setSummary}
                />
                <SaveChangesModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  onLeave={() => updateViewNotes(true)}
                />
              </Row>
            </>
          )}

          {editNote === true && (
            <>
              <Row>
                <Col>
                  <h4 className="mb-0 app-header-text">Edit Note</h4>
                </Col>
                <Col className="text-end">
                  <Button className="app-primary-color button" onClick={() => doneEditing()}>
                    Done
                  </Button>
                </Col>
              </Row>
              <Row>
                <EditNote
                  fetchNotes={getNotes}
                  note={currentNote}
                  family={activeFamily}
                  setViewNotes={setViewNotes}
                  setEditNote={setEditNote}
                  setShowToast={setShowToast}
                  setToastText={setToastText}
                  setToastBody={setToastBody}
                  saveIsEnabled={saveIsEnabled}
                  setSaveIsEnabled={setSaveIsEnabled}
                  setUserToSave={setUserToSave}
                  setSummary={setSummary}
                  myCase={myCase}
                />
                <SaveChangesModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  onLeave={() => updateViewNotes(true)}
                />
              </Row>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Notes;
