import React, { useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { unsavedChanges } from "../../App";
import Input from "../ui/Input";
import { postNote } from "../../services/httpClient";

interface CardState {
  item: any;
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const BedInspectionCard = ({ item, setOrgToSave, setShowToast, setToastText, setToastBody }: CardState) => {
  // const [passed, setPassed] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [infractionSaved, setInfractionSaved] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");

  // const onClickPass = () => {
  //   setPassed(true);
  // };

  const onClickFail = () => {
    setFailed(true);
  };

  const updateNote = (e: any) => {
    setNote(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
  };

  const onClickSaveNote = () => {
    const today = new Date();
    const todayString = today.toISOString().split("T")[0];

    const newNote = {
      notetype: "infraction",
      subject: {
        id: item.occupant_id,
        type: item.occupant_type,
      },
      fields: {
        type: "Bed",
        description: note,
        date: todayString,
      },
    };

    postNote(newNote, item?.case_id).then((res: any) => {
      setShowToast(true);
      setToastText("Infraction Note Created");
      setToastBody(item?.firstname + " " + item?.lastname);
      setInfractionSaved(true);
      setNote("");
      unsavedChanges.value = false;
      setOrgToSave(false);
      setFailed(false);
    });
  };

  const onClickCancelNote = () => {
    setNote("");
    unsavedChanges.value = false;
    setOrgToSave(false);
    setFailed(false);
  };

  const onClickUndo = () => {
    // setPassed(false);
    setFailed(false);
  };

  return (
    <>
      <Card key={item.name} className="bg-light mb-0">
        <Card.Body className="p-2">
          <Stack direction="horizontal" gap={3}>
            <Stack gap={2} className="align-self-center">
              <Card.Subtitle className="text-muted">
                <small>{item.name}</small>
              </Card.Subtitle>
            </Stack>
            {!item.occupant_id && <small className="fw-light fst-italic p-0 m-0">{"Vacant"}</small>}
            {/* {passed ? (
              <>
                <small className="app-secondary-color-inverse bg-light">Passed</small>
                <div className="vr" />
                <FontAwesomeIcon
                  icon={faUndo}
                  className={"app-primary-color-inverse bg-light button"}
                  onClick={() => onClickUndo()}
                />
              </>
            ) :  */}
            {infractionSaved ? (
              <small className="app-danger-color-inverse bg-light">Infraction Reported</small>
            ) : (
              item.occupant_id && (
                <>
                  {/* <Button className={"app-secondary-color button"} onClick={() => onClickPass()}>
                    Pass
                  </Button>
                  <div className="vr" /> */}
                  <Button className={"app-danger-color button"} onClick={() => onClickFail()}>
                    Fail
                  </Button>
                </>
              )
            )}
          </Stack>
        </Card.Body>
      </Card>
      {failed && (
        <>
          <Row className="align-items-center my-3">
            <Col xs={12}>
              <Input
                label={"Infraction Note"}
                type="text"
                value={note}
                onUpdate={(e: any) => updateNote(e)}
                hasFocus={true}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={8}>
              <Stack direction="horizontal" gap={3}>
                <Button className="app-secondary-color button" onClick={() => onClickSaveNote()}>
                  Save
                </Button>
                <div className="vr" />
                <Button className="app-primary-color button" onClick={() => onClickCancelNote()}>
                  Cancel
                </Button>
              </Stack>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BedInspectionCard;
