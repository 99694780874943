import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { faRightFromBracket, faBars, faClose, faHouse } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { fetchOrganizations, logout, switchOrg } from "../../services/httpClient";
import MainHeaderMenu from "./MainHeaderMenu";
import useApp from "../../utils/useApp";
import { unsavedChanges } from "../../App";
import SaveChangesModal from "./SaveChangesModal";
import SelectObject from "./SelectObject";

const MainHeader = () => {
  const { auth, setAuth, showMenu, setShowMenu, activePage, setActivePage }: any = useApp();
  const [activeOrgSettings, setActiveOrgSettings] = useState<boolean>(false);
  const [activeMyCases, setActiveMyCases] = useState<boolean>(false);
  const [activeUsers, setActiveUsers] = useState<boolean>(false);
  const [activeCases, setActiveCases] = useState<boolean>(false);
  const [activeGroups, setActiveGroups] = useState<boolean>(false);
  const [activeAnnouncements, setActiveAnnouncements] = useState<boolean>(false);
  const [activeShelter, setActiveShelter] = useState<boolean>(false);
  const [activeReports, setActiveReports] = useState<boolean>(false);
  const [org, setOrg] = useState<string>(auth?.profile?.userOrgID);
  const [orgs, setOrgs] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pendingRoute, setPendingRoute] = useState<string>("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);
  const [pendingOrg, setPendingOrg] = useState<string>("");
  const [isSwitching, setIsSwitching] = useState<boolean>(false);
  const currentVersion = localStorage.getItem("appVersion");

  let navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);

    setShowMenu(false);
    getOrgs();
    navigate("/");

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getOrgs = () => {
    fetchOrganizations()
      .then((res: any) => {
        var orgs = res[0].data;
        var orgObjects = orgs.map((o: any) => {
          return { key: o?.id, name: o?.name };
        });
        orgObjects = orgObjects.filter((o: any) => {
          return auth?.profile?.allOrgIDs.includes(o?.key);
        });
        setOrgs(orgObjects);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleHome = () => {
    setIsSwitching(false);

    if (unsavedChanges.value !== true) {
      if (auth?.profile?.role === "org_admin") {
        setActivePage("/settings");
      } else {
        setActivePage("/my-cases");
      }
      navigate("/");
    } else {
      setShowModal(true);
      setPendingRoute("/");
    }
  };

  const handleOpenCloseMenu = () => {
    setShowMenu(!showMenu);
  };

  const routeChange = (route: string) => {
    if (unsavedChanges.value !== true) {
      var tempRoute = route;
      if (route.startsWith("/users")) {
        tempRoute = "/users";
      }

      setActivePage(tempRoute.split("/"[1]));

      switch (tempRoute) {
        case "/settings":
          setActivePage("/settings");
          break;
        case "/my-cases":
          setActivePage("/my-cases");
          break;
        case "/users":
          setActivePage("/users");
          break;
        case "/cases":
          setActivePage("/cases");
          break;
        case "/groups":
          setActivePage("/groups");
          break;
        case "/announcements":
          setActivePage("/announcements");
          break;
        case "/shelter":
          setActivePage("/shelter");
          break;
        case "/reports":
          setActivePage("/reports");
          break;
        default:
          setActivePage("");
          break;
      }
      navigate(route);
    }
  };

  const handleLogout = () => {
    setIsSwitching(false);

    if (unsavedChanges.value !== true) {
      logout()
        .then((res: any) => {
          navigate("/login");
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setShowModal(true);
      setPendingRoute("/login");
    }
  };

  const handleProfile = () => {
    setIsSwitching(false);

    if (unsavedChanges.value !== true) {
      setActivePage("/users");
      navigate("/users/user/" + auth?.profile.id + "?profile=true");
    } else {
      setShowModal(true);
      setPendingRoute("/users/user/" + auth?.profile.id + "?profile=true");
    }
  };

  //menu selection
  useEffect(() => {
    const basePageUrl = activePage.split("/")[1];
    switch (basePageUrl) {
      case "settings":
        setActiveOrgSettings(true);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(false);
        break;
      case "my-cases":
        setActiveOrgSettings(false);
        setActiveMyCases(true);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(false);
        break;
      case "users":
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(true);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(false);
        break;
      case "cases":
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(true);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(false);
        break;
      case "groups":
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(true);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(false);
        break;
      case "announcements":
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(true);
        setActiveShelter(false);
        setActiveReports(false);
        break;
      case "shelter":
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(true);
        setActiveReports(false);
        break;
      case "reports":
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(true);
        break;
      default:
        setActiveOrgSettings(false);
        setActiveMyCases(false);
        setActiveUsers(false);
        setActiveCases(false);
        setActiveGroups(false);
        setActiveAnnouncements(false);
        setActiveShelter(false);
        setActiveReports(false);
        break;
    }
  }, [activePage]);

  const handleSwitchOrg = (orgId: string) => {
    if (unsavedChanges.value !== true) {
      setOrg(orgId);
      localStorage.removeItem("auth");
      localStorage.removeItem("expiration");
      setShowMenu(false);

      switchOrg(orgId)
        .then((res: any) => {
          const profile = res[0].data;
          setAuth({ profile, twofactor: true });
          unsavedChanges.value = false;
          if (auth?.profile?.role === "org_admin") {
            setActivePage("/settings");
          } else {
            setActivePage("/my-cases");
          }
          navigate("/");
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setShowModal(true);
      if (auth?.profile?.role === "org_admin") {
        setPendingRoute("/settings");
      } else {
        setPendingRoute("/my-cases");
      }
      setPendingOrg(orgId);
      setIsSwitching(true);
    }
  };

  return (
    <>
      <Navbar variant="dark" className="nav nav-header">
        <Container className="custom-container">
          <div>
            <Stack direction="horizontal">
              <OverlayTrigger
                placement="bottom-start"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{currentVersion}</Tooltip>}
              >
                <Navbar.Brand className="cursor-pointer" onClick={() => handleHome()}>
                  {isMobile ? (
                    <img src="/AndGo_Logo_White_Only.png" height="35" alt="And Go" />
                  ) : (
                    <img src="/AndGo_Logo_White_Horizontal.png" width="220" height="35" alt="And Go" />
                  )}
                </Navbar.Brand>
              </OverlayTrigger>
              <SelectObject
                label="Organization"
                value={org}
                options={orgs}
                skipInitialOption={true}
                cssClasses={orgs.length === 1 ? "app-primary-alt-color no-caret" : "app-primary-alt-color"}
                onUpdate={(e: any) => handleSwitchOrg(e.target.value)}
                disabled={orgs.length > 1 ? false : true}
              />
            </Stack>
          </div>
          <div>
            <Stack direction="horizontal">
              {auth?.profile?.role !== "support" ? (
                <Button className={"bg-transparent border-0 button-menu"} onClick={() => handleOpenCloseMenu()}>
                  {showMenu ? (
                    <FontAwesomeIcon icon={faClose} className="me-2" />
                  ) : (
                    <FontAwesomeIcon icon={faBars} className="me-2" />
                  )}
                  Menu
                </Button>
              ) : (
                <Button className={"bg-transparent border-0 button-menu"} onClick={() => handleHome()}>
                  <FontAwesomeIcon icon={faHouse} className="me-2" />
                  My Cases
                </Button>
              )}
              <Nav.Link className="" onClick={handleProfile}>
                Welcome {auth?.profile.preferredname ? auth?.profile.preferredname : auth?.profile.firstname}!
              </Nav.Link>
              <Nav.Link className="app-light-text" onClick={() => handleLogout()}>
                <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
                {!isMobile && "Sign Out"}
              </Nav.Link>
            </Stack>
          </div>
        </Container>
      </Navbar>
      <Navbar className="nav-base"></Navbar>
      {showMenu && (
        <MainHeaderMenu
          activeOrgSettings={activeOrgSettings}
          activeMyCases={activeMyCases}
          activeUsers={activeUsers}
          activeCases={activeCases}
          activeGroups={activeGroups}
          activeAnnouncements={activeAnnouncements}
          activeReports={activeReports}
          activeShelter={activeShelter}
          routeChange={routeChange}
        />
      )}
      {isSwitching ? (
        <SaveChangesModal
          showModal={showModal}
          setShowModal={setShowModal}
          onLeave={() => handleSwitchOrg(pendingOrg)}
        />
      ) : (
        <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path={pendingRoute} />
      )}
    </>
  );
};

export default MainHeader;
