import React, { useEffect, useState } from "react";
import { currentUrl } from "../../App";
import { useLocation } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { fetchBeds, postReportLog } from "../../services/httpClient";
import PageTitle from "../ui/PageTitle";
import BedInspectionCard from "./BedInspectionCard";

interface BedInspectionState {
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
  getData: boolean;
}

const BedInspection = ({ setOrgToSave, setShowToast, setToastText, setToastBody, getData }: BedInspectionState) => {
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [errMsg, setErrMsg] = useState("");
  const [beds, setBeds] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getData) {
      setIsLoading(true);
      getBeds();
    }
  }, [getData]);

  const getBeds = () => {
    fetchBeds()
      .then((res: any) => {
        const bedList = res[0].data;
        setBeds(bedList);
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onClickSubmit = () => {
    const report = {
      name: "Bed Inspection",
    };

    postReportLog(report).then((res: any) => {});

    setShowToast(true);
    setToastText("Bed Inspection Submitted");
    setToastBody("");
    setIsLoading(true);
    getBeds();
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <>
            <PageTitle title="Bed Inspection" type="section" />
            <Row className="pb-3 mt-2">
              <Col xs={12} lg={10} xl={8}>
                {beds.length === 0 ? (
                  <small>No Beds Created</small>
                ) : (
                  beds?.map((item: any, index: number) => (
                    <>
                      <Row>
                        <Col xs={12}>
                          <Row className="mb-1"></Row>
                          <BedInspectionCard
                            item={item}
                            setOrgToSave={setOrgToSave}
                            setShowToast={setShowToast}
                            setToastText={setToastText}
                            setToastBody={setToastBody}
                          />
                        </Col>
                      </Row>
                    </>
                  ))
                )}
              </Col>
            </Row>
            <Row className="pb-5">
              {beds.length > 0 && (
                <Col xs={12} lg={10} xl={8} className="d-flex align-items-center justify-content-center">
                  <Button className="app-secondary-color button" onClick={() => onClickSubmit()}>
                    Submit
                  </Button>
                </Col>
              )}
            </Row>
          </>
        </>
      )}
    </>
  );
};

export default BedInspection;
