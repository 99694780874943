import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Row, Spinner, Stack, Image } from "react-bootstrap";
import useApp from "../../../utils/useApp";
import { useLocation } from "react-router-dom";
import { currentUrl, unsavedChanges } from "../../../App";
import states from "../../../data/states.json";
import { fetchOrganization, patchOrganization } from "../../../services/httpClient";
import PageTitle from "../../ui/PageTitle";
import Input from "../../ui/Input";
import SelectObject from "../../ui/SelectObject";
import InputPhone from "../../ui/InputPhone";
import SaveChangesModal from "../../ui/SaveChangesModal";

interface State {
  key: string;
  name: string;
}

interface OrgDetailsState {
  setOrgToSave: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const OrgDetails = ({ setOrgToSave, setShowToast, setToastText, setToastBody }: OrgDetailsState) => {
  const { auth, showMenu }: any = useApp();
  const id = auth?.profile?.userOrgID;
  const location = useLocation();
  currentUrl.value = location.pathname;
  const [name, setName] = useState<string>("");
  const [profileId, setProfileId] = useState<string>();
  const [profileImage, setProfileImage] = useState<string>("");
  const [addressOne, setAddressOne] = useState<string>("");
  const [addressTwo, setAddressTwo] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [phone, setPhone] = useState<number | null>(null);
  const [billingContact, setBillingContact] = useState<string>("");
  const [billingEmail, setBillingEmail] = useState<string>("");
  const statesList: State[] = states;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveIsEnabled, setSaveIsEnabled] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  function validateEmail(email: string) {
    var regexp = /\S+@\S+\.\S+/;
    return regexp.test(email);
  }

  function validatePhone(phone: string) {
    let length = phone.length;
    return length === 11;
  }

  useEffect(() => {
    getOrg();
  }, [auth?.profile]);

  const getOrg = () => {
    setIsLoading(true);

    fetchOrganization(id)
      .then((res: any) => {
        const currentOrg = res[0].data;
        setName(currentOrg.name);
        setProfileId(res[0].data.profile_id);
        setAddressOne(currentOrg.address_line_one);
        setAddressTwo(currentOrg.address_line_two);
        setCity(currentOrg.city);
        setState(currentOrg.state);
        setZip(currentOrg.zip);
        setPhone(currentOrg.phonenumber === "" ? null : currentOrg.phonenumber);
        setBillingContact(currentOrg.billing_contact);
        setBillingEmail(currentOrg.billing_email);

        if (res[0].data?.profile.length > 0) {
          res[0].data?.profile?.map((p: any) => {
            const base64String = "data:image/jpeg;base64, " + p?.data;
            setProfileImage(base64String);
          });
        } else {
          setProfileImage("");
        }

        setIsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (saveIsEnabled) {
      unsavedChanges.value = true;
      setOrgToSave(true);
    } else {
      unsavedChanges.value = false;
      setOrgToSave(false);
    }
  }, [saveIsEnabled]);

  const saveOrg = () => {
    setIsLoading(true);

    const updatedOrg = {
      name: name,
      address_line_one: addressOne,
      address_line_two: addressTwo,
      city: city,
      state: state,
      zip: zip,
      phonenumber: phone,
      billing_contact: billingContact,
      billing_email: billingEmail,
    };

    patchOrganization(updatedOrg, id)
      .then((res: any) => {
        setSaveIsEnabled(false);
        setShowToast(true);
        setToastText("Organization Updated");
        setToastBody(name);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateName = (e: any) => {
    setName(e.target.value);
    if (e.target.value) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updateAddressOne = (e: any) => {
    setAddressOne(e.target.value);
    enableSave();
  };

  const updateAddressTwo = (e: any) => {
    setAddressTwo(e.target.value);
    enableSave();
  };

  const updateCity = (e: any) => {
    setCity(e.target.value);
    enableSave();
  };

  const updateState = (e: any) => {
    setState(e.target.value);
    enableSave();
  };

  const updateZip = (e: any) => {
    setZip(e.target.value);
    enableSave();
  };

  const updatePhone = (e: any) => {
    setPhone(e);
    let validPhone = validatePhone(e);
    if (validPhone) {
      enableSave();
    } else {
      setSaveIsEnabled(false);
    }
  };

  const updateBillingContact = (e: any) => {
    setBillingContact(e.target.value);
    enableSave();
  };

  const updateBillingEmail = (e: any) => {
    setBillingEmail(e.target.value);

    let validEmail = validateEmail(e.target.value);
    if (validEmail) {
      enableSave();
    } else {
      setSaveIsEnabled(false);
    }
  };

  function enableSave() {
    if (name) {
      setSaveIsEnabled(true);
    } else {
      setSaveIsEnabled(false);
    }
  }

  const onClickSelect = (event: any) => {
    fileInputRef?.current?.click();
  };

  const handleFileChange = (event: any) => {
    setErrMsg("");
    setIsImageLoading(true);

    const file = event.target.files[0];
    if (file) {
      // Check if the file is an image
      if (file.type.startsWith("image/")) {
        // The file is an image; proceed with upload
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (reader.result) {
            uploadFileData(reader.result.toString().split(",")[1]);
          }
        };
        reader.onerror = function (error) {
          setIsImageLoading(false);
        };
      } else {
        // Not an image; display an error or ignore the file
        setErrMsg("Please select a valid image file");
        setIsImageLoading(false);
      }
    } else {
      setIsImageLoading(false);
    }
  };

  async function uploadFileData(data: any) {
    setProfileImage(data);

    var image;
    if (profileId) {
      image = {
        profile_id: profileId,
        profile_data: data,
      };
    } else {
      image = {
        profile_data: data,
      };
    }

    patchOrganization(image, id)
      .then((res: any) => {
        const base64String = "data:image/jpeg;base64, " + data;
        setProfileImage(base64String);

        setShowToast(true);
        const toast = name;
        setToastText("Organization Logo Updated");
        setToastBody(toast);
        setIsImageLoading(false);
      })
      .catch((err: any) => {
        setErrMsg(err?.response?.data?.msg);
        setIsImageLoading(false);
      });
  }

  return (
    <>
      {isLoading ? (
        <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
          <Button size="sm" className="spinner-button spinner-button-main">
            <Spinner animation="border" style={{ color: "#F6893D" }} />
          </Button>
        </Container>
      ) : (
        <>
          <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
            {errMsg}
          </p>
          <PageTitle
            title=""
            buttonTitle="Save"
            onButtonClick={saveOrg}
            type="section"
            buttonDisabled={!saveIsEnabled}
          />
          <Row className="mb-3"></Row>
          <Stack gap={3}>
            <Row>
              <Col xs={12} sm={12} md={8} lg={8} xl={6}>
                <Stack gap={3}>
                  <Row>
                    <Col xs={12}>
                      <Input
                        label="Name"
                        type="text"
                        value={name}
                        onUpdate={(e: any) => updateName(e)}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <h4 className="app-header-text">Address</h4>
                  <Row>
                    <Col xs={12}>
                      <Input
                        label="Address Line One"
                        type="text"
                        value={addressOne}
                        onUpdate={(e: any) => updateAddressOne(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Input
                        label="Address Line Two"
                        type="text"
                        value={addressTwo}
                        onUpdate={(e: any) => updateAddressTwo(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Input label="City" type="text" value={city} onUpdate={(e: any) => updateCity(e)} />
                    </Col>
                    <Col xs={6}>
                      <SelectObject
                        label="State"
                        value={state}
                        options={statesList}
                        onUpdate={(e: any) => updateState(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <Input label="Zip" type="text" value={zip} onUpdate={(e: any) => updateZip(e)} />
                    </Col>
                  </Row>
                </Stack>
              </Col>
              <Col xs={4} sm={4} md={4} lg={3} xl={3}>
                <Stack gap={3}>
                  {isImageLoading ? (
                    <Button size="sm" className="spinner-button spinner-button-main">
                      <Spinner animation="border" style={{ color: "#F6893D" }} />
                    </Button>
                  ) : (
                    <>
                      <Image src={profileImage} rounded className="image-logo" />
                      <Button className="app-primary-color-inverse button" onClick={(e: any) => onClickSelect(e)}>
                        {profileImage ? "Change Logo" : "Upload Logo"}
                      </Button>
                    </>
                  )}
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    onChange={handleFileChange} // Handle the file change
                  />
                </Stack>
              </Col>
            </Row>
            <h4 className="app-header-text">Billing</h4>
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Input
                  label="Contact"
                  type="text"
                  value={billingContact}
                  onUpdate={(e: any) => updateBillingContact(e)}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Input label="Email" type="text" value={billingEmail} onUpdate={(e: any) => updateBillingEmail(e)} />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <InputPhone label="Phone" value={phone} onUpdate={(e: any) => updatePhone(e)} />
              </Col>
            </Row>
          </Stack>
          <SaveChangesModal showModal={showModal} setShowModal={setShowModal} path="/settings" />
        </>
      )}
    </>
  );
};

export default OrgDetails;
