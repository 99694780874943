import { faCheck, faClose, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Card, Col, Stack } from "react-bootstrap";
import { deleteBed, patchBed } from "../../services/httpClient";
import { unsavedChanges } from "../../App";
import Input from "../ui/Input";

interface CardState {
  item: any;
  getItems: any;
  setOrgToSave: any;
  setErrMessage: any;
  setShowToast: any;
  setToastText: any;
  setToastBody: any;
}

const BedManagementCard = ({
  item,
  getItems,
  setOrgToSave,
  setErrMessage,
  setShowToast,
  setToastText,
  setToastBody,
}: CardState) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [itemName, setItemName] = useState<string>(item?.name);

  const onClickEdit = () => {
    setIsEditing(true);
  };

  const onClickEditFinal = () => {
    const updatedBed = {
      name: itemName,
    };

    patchBed(updatedBed, item?.id)
      .then((res: any) => {
        unsavedChanges.value = false;
        setOrgToSave(false);
        setShowToast(true);
        setToastText("Bed Updated");
        setToastBody(itemName);
        setIsEditing(false);
        getItems();
      })
      .catch((err: any) => {
        setErrMessage(err?.response?.data?.msg);
      });
  };

  const onClickCancelEdit = () => {
    unsavedChanges.value = false;
    setOrgToSave(false);
    setIsEditing(false);
    setErrMessage(undefined);
  };

  const onUpdateName = (e: any) => {
    setItemName(e.target.value);
    unsavedChanges.value = true;
    setOrgToSave(true);
    setErrMessage(undefined);
  };

  const onClickDelete = () => {
    setIsDeleting(true);
  };

  const onClickDeleteFinal = () => {
    deleteBed(item?.id)
      .then((res: any) => {
        setShowToast(true);
        setToastText("Bed Deleted");
        setToastBody(itemName);
        setIsDeleting(false);
        getItems();
      })
      .catch((err: any) => {
        setIsDeleting(false);
      });
  };

  const onClickCancelDelete = () => {
    setIsDeleting(false);
    setErrMessage(undefined);
  };

  const renderEdit = () => {
    return (
      <Card key={item.name} className="bg-light mb-1">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Col>
              <Input label="Name" type="text" value={itemName} onUpdate={(e: any) => onUpdateName(e)} />
            </Col>
            <FontAwesomeIcon
              className="text-muted me-1 cursor-pointer"
              icon={faCheck}
              onClick={() => onClickEditFinal()}
            />
            <div className="vr" />
            <FontAwesomeIcon
              className="text-muted me-1 cursor-pointer"
              icon={faClose}
              onClick={() => onClickCancelEdit()}
            />
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  const renderDelete = () => {
    return (
      <Card key={item.name} className="bg-light mb-1">
        <Card.Body>
          <Stack direction="horizontal" gap={3}>
            <Stack style={{ justifyContent: "center" }} gap={2}>
              <Card.Subtitle>Remove {item?.name}?</Card.Subtitle>
            </Stack>
            <FontAwesomeIcon
              className="text-muted me-1 cursor-pointer"
              icon={faCheck}
              onClick={() => onClickDeleteFinal()}
            />
            <div className="vr" />
            <FontAwesomeIcon
              className="text-muted me-1 cursor-pointer"
              icon={faClose}
              onClick={() => onClickCancelDelete()}
            />
          </Stack>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      {isEditing ? (
        <>{renderEdit()}</>
      ) : isDeleting ? (
        <>{renderDelete()}</>
      ) : (
        <Card key={item.name} className="bg-light mb-0">
          <Card.Body className="p-2">
            <Stack direction="horizontal" gap={3}>
              <Stack gap={2} className="align-self-center">
                <Card.Subtitle className="text-muted">
                  <small>{item.name}</small>
                </Card.Subtitle>
              </Stack>
              {item.occupant_id ? (
                <small className="p-0 m-0">{item.firstname ? item.firstname + " " + item.lastname : "Missing"}</small>
              ) : (
                <small className="fw-light fst-italic p-0 m-0">{"Vacant"}</small>
              )}
              <FontAwesomeIcon
                className="text-muted me-1 cursor-pointer"
                icon={faEdit}
                onClick={(e) => onClickEdit()}
              />
              <div className="vr" />
              <FontAwesomeIcon
                className="text-muted me-1 cursor-pointer"
                icon={faTrash}
                onClick={(e) => onClickDelete()}
              />
            </Stack>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default BedManagementCard;
