import React, { useEffect, useState } from "react";
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import PageTitle from "../ui/PageTitle";
import ToastMsg from "../ui/ToastMsg";
import useApp from "../../utils/useApp";
import { trackNavigationEvent } from "../../utils/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate, faBriefcase, faBullseye, faUserFriends } from "@fortawesome/free-solid-svg-icons";
import useMenuToggle from "../../utils/useMenuToggle";
import BedAssignment from "./BedAssignment";
import BedManagement from "./BedManagement";
import BedOccupancy from "./BedOccupancy";
import BedInspection from "./BedInspection";

const Shelter = () => {
  const { showMenu }: any = useApp();
  const toggleMenu = useMenuToggle();
  const [activeManage, setActiveManage] = useState<boolean>(false);
  const [activeAssign, setActiveAssign] = useState<boolean>(false);
  const [activeOccupancy, setActiveOccupancy] = useState<boolean>(false);
  const [activeInspection, setActiveInspection] = useState<boolean>(false);
  const [orgToSave, setOrgToSave] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [toastBody, setToastBody] = useState<string>("");
  const [toastText, setToastText] = useState<string>("");
  //get active tab data states
  const [getManagementData, setGetManagementData] = useState<boolean>(true);
  const [getAssignmentData, setGetAssignmentData] = useState<boolean>(false);
  const [getOccupancyData, setGetOccupancyData] = useState<boolean>(false);
  const [getInspectionData, setGetInspectionData] = useState<boolean>(false);

  useEffect(() => {
    setActiveManage(true);
    setActiveAssign(false);
    setActiveOccupancy(false);
    setActiveInspection(false);

    trackNavigationEvent("switch_view", "shelter_bed_manage");
  }, []);

  const onNavClick = (route: string) => {
    if (route) {
      trackNavigationEvent("switch_view", "shelter_" + route);
    }

    switch (route) {
      case "bed_management":
        setActiveManage(true);
        setActiveAssign(false);
        setActiveOccupancy(false);
        setActiveInspection(false);

        setGetManagementData(true);
        setGetAssignmentData(false);
        setGetOccupancyData(false);
        setGetInspectionData(false);
        break;
      case "bed_assignment":
        setActiveManage(false);
        setActiveAssign(true);
        setActiveOccupancy(false);
        setActiveInspection(false);

        setGetManagementData(false);
        setGetAssignmentData(true);
        setGetOccupancyData(false);
        setGetInspectionData(false);
        break;
      case "bed_occupancy":
        setActiveManage(false);
        setActiveAssign(false);
        setActiveOccupancy(true);
        setActiveInspection(false);

        setGetManagementData(false);
        setGetAssignmentData(false);
        setGetOccupancyData(true);
        setGetInspectionData(false);
        break;
      case "bed_inspection":
        setActiveManage(false);
        setActiveAssign(false);
        setActiveOccupancy(false);
        setActiveInspection(true);

        setGetManagementData(false);
        setGetAssignmentData(false);
        setGetOccupancyData(false);
        setGetInspectionData(true);
        break;
      default:
        setActiveManage(true);
        setActiveAssign(false);
        setActiveOccupancy(false);
        setActiveInspection(false);
        break;
    }
  };

  return (
    <>
      <ToastMsg showToast={showToast} setShowToast={setShowToast} toastText={toastText} toastBody={toastBody} />

      <Container className={showMenu ? "lg div-main-menu" : "lg div-main"}>
        <Tab.Container defaultActiveKey={"bed_management"}>
          <Row>
            <Col xs={12} md={3}>
              <Nav
                variant="pills"
                className={
                  showMenu
                    ? "flex-column sticky-top mycase-menu-open-sticky"
                    : "flex-column sticky-top mycase-menu-sticky"
                }
              >
                <Nav.Item className="mb-2">
                  <PageTitle title={"Shelter"} />
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="bed_management"
                    className={activeManage ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("bed_management")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faBriefcase} className={"me-2"} />
                      {toggleMenu ? "Bed Management" : "Management"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="bed_assignment"
                    className={activeAssign ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("bed_assignment")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faArrowsRotate} className={"me-2"} />
                      {toggleMenu ? "Bed Assignment" : "Assignment"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="bed_occupancy"
                    className={activeOccupancy ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("bed_occupancy")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faUserFriends} className={"me-2"} />
                      {toggleMenu ? "Bed Occupancy" : "Occupancy"}
                    </>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="d-grid">
                  <Nav.Link
                    eventKey="bed_inspection"
                    className={activeInspection ? "nav-vertical-active" : "nav-vertical"}
                    onClick={() => onNavClick("bed_inspection")}
                    disabled={orgToSave === true ? true : false}
                  >
                    <>
                      <FontAwesomeIcon icon={faBullseye} className={"me-2"} />
                      {toggleMenu ? "Bed Inspection" : "Inspection"}
                    </>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col xs={12} md={9} className="mt-2">
              <Tab.Content>
                <Tab.Pane eventKey="bed_management">
                  <BedManagement
                    setOrgToSave={setOrgToSave}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                    getData={getManagementData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="bed_assignment">
                  <BedAssignment
                    setOrgToSave={setOrgToSave}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                    getData={getAssignmentData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="bed_occupancy">
                  <BedOccupancy
                    setOrgToSave={setOrgToSave}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                    getData={getOccupancyData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="bed_inspection">
                  <BedInspection
                    setOrgToSave={setOrgToSave}
                    setShowToast={setShowToast}
                    setToastText={setToastText}
                    setToastBody={setToastBody}
                    getData={getInspectionData}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default Shelter;
